@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: url("./images/parchment.jpg") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.questDescription {
  font-size: 1em;
}

.noFlexGrow {
  flex-grow:0;
}

.YourInviteCode {
  font-weight: bold;
}

.addNewQuest, .confirmQuestCompletion, .templateScreen {
  background-color: #ffffff;
  width: 100%;
  min-height: 360px;
  max-width: 720px;
}

.RewardScreen {
  border-radius: 5px;
  padding: 16px;
}

.IndividualReward input {
  height: 24px;
  width: 24px;
  margin-right: 16px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.RewardsList>div:nth-child(odd) {
  background-color: #AAAAFF;
}

.templateFull {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  max-width: 720px;
}

.QuestCompletedScreen {
  font-family: 'MedievalSharp', cursive;
  color: #cccccc;
}

.QuestCompletedScreen h2 {
  text-align: center;
  margin-top: 128px;
  font-size: 42px;
  font-weight: bold;
}

.QuestCompletedScreen p {
  text-align: center;
  margin-top: 32px;
  font-size: 32px;
}

.confirmQuestCompletion {
  font-family: 'MedievalSharp', cursive;
  padding: 16px;
}

.confirmQuestCompletion h2 {
  font-size: 2em;
}

.confirmQuestDescription {
  font-size: 1.4em;
}

.spaceAtTheBottom {
  margin-bottom: 64px;
}

.addNewQuest h2, .templateScreen h2 {
  font-size: 1.5em;
  margin-left: 12px;
  margin-top: 12px;
}

.templateScreen p {
  text-indent: 2em;
  padding-left: 16px;
  padding-right: 16px;
}

.partyMemberList {
  margin-left: 32px;
}

.templateScreen h3 {
  font-size: 1.2em;
  margin-left: 12px;
  margin-top: 12px;
}

.addNewQuest label {
  width: 100%;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.buttonrow {
  margin-top: 16px;
}

.heroDisplaySmall {
  background-color: #CCCCCC;
}

.characterSheetLabel {
  width: 100%;
  display: block;
  margin-top: 8px;
}

.smallHeroImage {
  height: 48px;
  display: inline-block;
  margin-right: 14px;
}

.heroSmallDisplayName {
  font-family: 'MedievalSharp', cursive;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  display: inline-block;
}

.heroSmallExperience {
  font-family: 'MedievalSharp', cursive;
  font-size: 28px;
}

.heroSmallEditDetails {
  width: 32px;
  padding-top: 2px;
  padding-bottom: 2px;
  /*display: inline-block;
  height: 32px;
  margin-left: 16px;
  margin-right: 16px;
  font-size: 24px;
  */
}

.cancelButton {
  width: 22px;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 22px;
  position: absolute;
  top:0;
  right:0;
}

.bottomMenuButton {
  height: 54px;
}

@media only screen and (max-width: 600px) {
  .smallHeroImage {
    height: 32px;    
  }
  .heroSmallDisplayName {
    font-size: 18px;
  }
  .heroSmallExperience {
    font-size: 16px;
  }
}

.heroXPDisplay {
  display: inline-block;
  width: 52px;
  font-size: 12px;
  margin-left: 8px;
}
.heroCurrentXP, .heroNextLevelXP {
  width: 100%;
  text-align: center;
}

.heroCurrentXP {
  border-bottom: 1px solid #000000;
}

.CreatePartyFooter {
  text-align: center;
}

.regularInput {
  padding-top: 4px;
  padding-bottom: 4px;
}

.buttonRightOfInput {
  display: inline-block;
  width: 158px;
  padding-left: 6px;
  padding-right: 6px;
}

.completeQuestButton {
  
}

.characterSheetLabel {
  display: block;
}

.characterSheetInput {
  width: 100%;
}

.giveMeSomeSpace {
margin-right: 12px;
}

.listContainer {
  max-height: 78vh;
  max-width: 864px;
  margin-left: auto;
  margin-right: auto;

}

.questList {
  background-image : url("./paperbg.png");
  box-shadow: 4px 4px #555555;
  position: relative;
}


.questList div {
  display: inline-block;
/*  background-color: #CCCCCC;*/
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.IndividualReward {
  background-image : url("./paperbg.png");
  box-shadow: 4px 4px #555555;
  padding: 6px;
  margin-bottom: 12px;
}

.RewardsFooter {
  margin-top: 16px;
}

.questName {
  font-size: 1.1em;
  font-family: 'MedievalSharp', cursive;
  font-weight: bold;
  width: 80%;
  margin-left: -6px;
}
.questSize {
  font-size: .8em;
  font-family: 'MedievalSharp', cursive;
  font-weight: 300;
  display:  inline-block;
  margin-left: 16px;
}
.questSize::before {
  content: "[";
}
.questSize::after {
  content: "]";
}

.questReward {
  width: 100%;
}

.UpdateCharacterSheetButton {
  width: 140px;
  font-size: .9em;
}


.buttonsCentered {
  text-align: center;
  justify-content: center;
}

.cancelCompleteQuestButton {
  color: #FF0000;
}

/** FOR FIREWORKS **/
canvas {
	cursor: crosshair;
	display: block;
}

.SortButton {
  width: 72px;
  margin-bottom: -18px;
  z-index: 1000;
  margin-top: -16px;
  font-size: .8em;
  height: 32px;
  padding-top: 4px;
}

.QuestCompletedScreen {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color:#000000;
  color: #DDDDDD;
}

.BackToQuestsFromQuestComplete {
  position: absolute;
  background-color: #CCFFCC;
  color: #FFFFFF;
  bottom: 50px;
  right: 20px;
}

.clearFix {
  clear: both;
}

button.active {
  background-color: #3333FF;
}

@media only screen and (max-width: 600px) {
  .logoutButton {
    width: 64px;
    content: "X";
    font-size: .8em;
  }
  .hiddenMobile {
    display: none !important;
  }
  .heroSmallEditDetails {
    font-size: .8em;
  }
  .heroDisplaySmall {
    height: 42px;
  }
  .bottomMenuButton {
    font-size: .9em;
    padding-top: 4px;
    padding-bottom: 4px;
    height: 38px;
  }
  .completeQuestButton {
    float: none;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .cancelCompleteQuestButton {
    float: none;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .spaceAtTheBottom {
    margin-bottom: 32px;
  }

}